<template>
  <div class="row mx-0 justify-content-center text-center p-3 position-relative">
    <div class="col-12 green-text-dark font12 bold mb-1">
      DONATING AS:
    </div>
    <div class="col-12 green-text-dark">
      <span v-if="user">
        {{ donatingAs.displayName }}
      </span>
      <span v-else>
        <span v-if="anonymous.donorType === 'organisation'">{{anonymous.organisationName}}</span>
        <span v-else>{{anonymous.donorName}} {{anonymous.donorSurname}}</span>
      </span>
    </div>
    <div class="col-12 font12" v-html="displayAddress">
    </div>
    <div class="onhover border_radius_top" :class="{show: isHover1 === true }" @mouseover="isHover1 = true" @mouseleave="isHover1 = false">
      <div class="row m-0 h-100 justify-content-center align-items-center">
        <div class="col-auto">
          <Button color="darkgrey" size="small" btnText="EDIT" icon="arrow" @buttonClicked="goTo">
            <IconEdit size="size16" />
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'

export default {
  name: 'Review Donation Donor Summary',
  props: ['user', 'sadaqahUserDonatingType', 'anonymous', 'donatingAs'],
  emits: ['goTo'],
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    IconEdit: defineAsyncComponent(() => import('@/components/icons/IconEdit.vue'))
  },
  data () {
    return {
      isHover1: false
    }
  },
  computed: {
    displayAddress () {
      if (this.user && this.donatingAs && this.donatingAs.address) {
        if (this.donatingAs.address.country && this.donatingAs.address.country !== undefined) {
          return this.donatingAs.address.addressLine1 + ', ' + this.donatingAs.address.town + ', ' + this.donatingAs.address.country + ', ' + this.donatingAs.address.postalCode
        } else {
          return this.donatingAs.address.addressLine1 + ', ' + this.donatingAs.address.town + ', ' + this.donatingAs.address.postalCode
        }
      } else if (this.anonymous && this.anonymous.address) {
        if (this.anonymous.address.country && this.anonymous.address.country !== undefined) {
          return this.anonymous.address.addressLine1 + ', ' + this.anonymous.address.town + ', ' + this.anonymous.address.country + ', ' + this.anonymous.address.postalCode
        } else {
          return this.anonymous.address.addressLine1 + ', ' + this.anonymous.address.town + ', ' + this.anonymous.address.postalCode
        }
      } else {
        return null
      }
    }
  },
  methods: {
    goTo () {
      this.$emit('goTo')
    }
  }
}
</script>
<style scoped>
.onhover {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.8);
  opacity: 0;
}
.onhover.show {
  opacity: 1;
}
.border_radius_top {
  border-top-right-radius: 22px;
  border-top-left-radius: 22px;
}
</style>
